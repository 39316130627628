<template>
    <div style="text-align: left;" class="bg">
        <div class="p-t-60 m-b-20 newBodyWidth" style="margin-left:auto;margin-right: auto;">
            <el-card>
                <div class="m-l-55 m-r-55">
                    <div class="text-center"><img src="@/assets/images/coupon/receiveCoupon.png" alt="" style="height:50px"></div>
                    <el-button-group>
                        <el-button :type="ticketKind==0?'primary':''" @click="setType(0)" size="mini">满减券</el-button>
                        <el-button :type="ticketKind==1?'primary':''" @click="setType(1)" size="mini">现金券</el-button>
                        <!-- <el-button :type="ticketKind==2?'primary':''" @click="setType(2)" size="mini">物流券</el-button> -->
                    </el-button-group>
                    <div v-if="prlductData.content" class="items">
                        <div v-for="(v,i) of prlductData.content" class="item" :key="v.id">
                            <div class="box">
                                <coupon3 :v="v"></coupon3>
                            </div>
                        </div>
                    </div>
                    <el-pagination class="zdfy" v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
                    </el-pagination>
                </div>
            </el-card>
        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { ticket } from "@/service"
export default {
    name: "receiveCoupon",
    components: {
},
    data() {
        return {
            ticketKind: 0,
            prlductData: {},
            limit: 20,
            start: 0,
        }
    },
    mounted() {
        this.queryAllTicket()
        //监听登录事件
        this.eventBus/*.$off(this.$oucy.msgKeys.LOGIN_SUCCESS)*/.$on(this.$oucy.msgKeys.LOGIN_SUCCESS,(res)=>{
            console.log('登录成功');
            this.queryAllTicket()
        });
    },
    methods: {
        setType(v) {
            if (this.ticketKind == v) return
            this.ticketKind = v
            this.prlductData = {}
            // this.limit = 20
            this.start = 0
            this.queryAllTicket(v)

        },

        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            // me.queryAllTicket();
            this.queryAllTicket()
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            // me.queryAllTicket();
            this.queryAllTicket()
        },

        // 明细
        queryAllTicket() {
            ticket.queryAllTicket({
                isEffective: false,
                ticketIsPublish: true,
                limit: this.limit,
                start: this.start,
                ticketKind: this.ticketKind
            }).then(res => {
                this.prlductData = res
            })
        },

    }
}
</script>
<style scoped lang="less">
.bg {
    background: url('../../../assets/images/coupon/bg.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
}

.payimg {
    display: flex;
    align-content: center;
}

.zdtitle {
    padding-bottom: 15px;
}

.zdfy {
    padding-top: 20px;
}

.head {
    width: 32px;
    height: 32px;
    border-radius: 32px;
}

.c-67973D {
    color: #67973D;
}

.c-2090FF {
    color: #2090FF;
}

.items {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}

@media screen and (max-width: 1920px) {
    .item {
        width: 25%;
    }
}

@media screen and (max-width: 1600px) {
    .item {
        width: 33.333%;
    }
}

.item {
    // width: 33.333%;
    // margin: 15px;
    padding: 15px;
    box-sizing: border-box;
}
.box{

}
</style>